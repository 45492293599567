import languages from "./ru/languages"
import pages from "./ru/pages"
import messages from "./ru/messages"
import dialogs from "./ru/dialogs"
import components from "./ru/components"
import adminPanel from "./ru/admin-panel"

export  default {
    languages,
    pages,
    messages,
    dialogs,
    components,
    adminPanel,
}
