<template>
    <div>
        <v-container class="px-2 pb-2 mt-6">
            <v-row class="mt-n7" no-gutters justify="space-between">
                <v-img
                    class="mx-auto"
                    width="154"
                    style="max-width: 154px"
                    src="@/assets/images/logo/noodzly-logo.png"
                />
            </v-row>
        </v-container>

        <v-container fluid class="h-full" style="align-items: center; justify-content: center">
            <v-flex text-center>
                <div v-if="loading">
                    <h1>
                        {{ $t('pages.user.payment.paypal.payment_processing') }}
                    </h1>
                </div>
    
                <div v-if="!loading && !success">
                    <h1>
                        {{ $t('pages.user.payment.paypal.payment_failed') }}
                    </h1>
                </div>
    
                <div v-if="!loading && success">
                    <h1>
                        {{ $t('pages.user.payment.paypal.payment_success') }}
                    </h1>
                    <tick-mark-icon :height="84" :width="84" class="mt-4" />
                </div>
            </v-flex>
        </v-container>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import TickMarkIcon from "@/components/app/common/TickMarkIcon";

export default {
    name: "PaypalSuccess",
    data() {
        return {
            loading: true,
            success: false,
        }
    },
    computed: {

    },
    methods: {
        ...mapActions(['capturePaypalPayment']),
    },
    components: {
        TickMarkIcon,
    },
    async mounted() {

    },
    created() {
        const query = this.$route.query;
        const orderId = query.token;

        if (orderId) {
            try {
                this.capturePaypalPayment({ orderId }).then((response) => {
                    if (response.success == true) {                        
                        this.loading = false;
                        this.success = true;

                        setTimeout(() => {
                            this.$router.push({ name: 'user.own.profile.wallet'})
                        }, 2000);
                    }
                }).catch(() => {
                    this.loading = false;
                    this.success = false;

                    setTimeout(() => {
                        this.$router.push({ name: 'user.own.profile.wallet'})
                    }, 2000);
                });
            } catch (error) {
                this.success = false;
            }
        } else {
            this.loading = false;
            this.success = false;
        }
    }
}
</script>

<style scoped lang="scss">

</style>
