<template>
    <div>
        <div v-if="template === 'sleeve'">
            <v-row>
                <v-col cols="4" class="text-left sleeve-links" v-for="(group, groupIndex) in staticLinks" :key="groupIndex">
                    <ul class="splash-links text-caption">
                        <h3>{{ group.header }}</h3>
                        <div v-for="(link, linkIndex) in group.links" :key="linkIndex">
                            <template v-if="link.custom">
                                <CCBillComplaintFormLink />  
                            </template>
                            <template v-else-if="link.popup">
                                <span @click="$emit('openContactSupportPopup')">{{ $t('components.static_links.contact_support') }}</span>
                            </template>
                            <template v-else>
                                <span>
                                    <router-link :to="link.to">{{ link.name }}</router-link>
                                </span>
                            </template>
                        </div>
                    </ul>
                </v-col>
            </v-row>
        </div>

        <div v-if="template === 'simple'">
            <v-row>
                <v-col cols="4" v-for="(group, groupIndex) in staticLinks" :key="groupIndex">
                    <ul>
                        <h3>{{ group.header }}</h3>
                        <div v-for="(link, linkIndex) in group.links" :key="linkIndex">
                            <template v-if="link.custom">
                                <CCBillComplaintFormLink />  
                            </template>
                            <template v-else-if="link.popup">
                                <span @click="$emit('openContactSupportPopup')">{{ $t('components.static_links.contact_support') }}</span>
                            </template>
                            <template v-else>
                                <span>
                                    <router-link :to="link.to">{{ link.name }}</router-link>
                                </span>
                            </template>
                        </div>
                    </ul>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import CCBillComplaintFormLink from "@/components/app/common/CCBillComplaintFormLink";

export default {
    props: {
        template: {
            type: String,
            default: 'simple',
        }
    },
    name: "StaticPageLinks",
    data() {
        return {
            staticLinks: [
                {
                    header: 'Resources',
                    links: [
                        { to: '/faq', name: 'FAQs' },
                    ],
                },
                {
                    header: 'Support',
                    links: [
                        { name: 'Complaints/Takedown Request', custom: true },
                        { name: 'Contact Support', popup: true },
                        // { to: '/contact', name: 'Contact' },
                    ],
                },
                {
                    header: 'Legal',
                    links: [
                        { to: '/terms', name: 'Terms & Conditions' },
                        { to: { name: 'anti_fraud_policy' }, name: 'AML & Anti-Fraud' },
                        { to: '/privacy-policy', name: 'Privacy Policy' },
                        { to: { name: 'anti-slavery-trafficking' }, name: 'Anti-Slavery' },
                        { to: { name: 'standard-agreement-noodzly-noodzler' }, name: 'Agreement' },
                        { to: { name: 'dmca-takedown-policy' }, name: 'DMCA' },
                    ],
                },
            ],
        }
    },
    components: {
        CCBillComplaintFormLink,
    },
}
</script>

<style lang="scss">

.splash-links {
    padding: 0 !important;
    div {
        font-size: 0.5rem;
    }
}
</style>
