<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{ creator && typeof creator.user !== 'undefined' ? creator.user.name : '' }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.videos.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <div v-show="video.overlayUrl" id="plyr-wrapper">
                                <video :src="video.overlayUrl" class="plyr" id="player" playsinline controls :data-poster="video.thumbnailUrl" />
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field :value="moment(video.created_at).format('LLL')" disabled></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <v-row>
            <v-col>
                <v-btn class="error" @click="dialogDelete = true">Delete</v-btn>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-h5 align-center justify-center text-center full-width">Are you sure you want <br>to delete this photo?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDelete = false">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="handleDelete">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import Video from "../../../models/admin/Video";
    import Creator from "../../../models/admin/Creator";
    import Plyr from "plyr";
    import {mapMutations} from "vuex";

    export default {
        name: "videos-show",
        components: {AdminDashboardLayout},
        data: function () {
            return {
                parent_page: {name: 'admin.videos.index'},
                video: {},
                creator: null,
                dialogDelete: false,
                player: {},
            }
        },
        watch: {
            async video(value) {
                if (value && value.creator_id) {
                    this.creator = await Creator.include('user').find(value.creator_id)
                }
            },
        },
        methods: {
            ...mapMutations(['showSnackBar']),
            handleDelete() {
                this.video.delete().then(() => {
                    this.showSnackBar(this.$t('messages.success'))
                    this.$router.push(this.parent_page)
                }).catch((e) => {
                    console.log(e)
                })
            },
        },
        async mounted() {
            const videoId = this.$route.params.id
            this.video = await Video.find(videoId)
            this.player = new Plyr('#player');
        },
    }
</script>

<style lang="scss">
    @import "~plyr/src/sass/plyr";
    .plyr {
        height: 85vh;
        width: 75vw;
        object-fit: cover;
    }
</style>
