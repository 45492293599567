import { render, staticRenderFns } from "./StoryViewFullscreen.vue?vue&type=template&id=79782e95&scoped=true&"
import script from "./StoryViewFullscreen.vue?vue&type=script&lang=js&"
export * from "./StoryViewFullscreen.vue?vue&type=script&lang=js&"
import style0 from "./StoryViewFullscreen.vue?vue&type=style&index=0&id=79782e95&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79782e95",
  null
  
)

export default component.exports

/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Touch from 'vuetify/lib/directives/touch'
installDirectives(component, {Touch})
