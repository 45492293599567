<template>
    <div>
        <v-container class="px-2 pb-2 mt-6">
            <v-row class="mt-n7" no-gutters justify="space-between">
                <v-img
                    class="mx-auto"
                    width="154"
                    style="max-width: 154px"
                    src="@/assets/images/logo/noodzly-logo.png"
                />
            </v-row>
        </v-container>

        <v-container fluid class="h-full" style="align-items: center; justify-content: center">
            <v-flex text-center>
                <div>
                    <h1>
                        {{ $t('pages.user.payment.paypal.payment_cancelled') }}
                    </h1>
                </div>
            </v-flex>
        </v-container>
    </div>
</template>

<script>
export default {
    name: "PaypalCancel",
    created() {
        setTimeout(() => {
            this.$router.push({ name: 'user.own.profile.wallet'})
        }, 2000);
    }
}
</script>

<style scoped lang="scss">

</style>
