import { getCookie } from "@/helpers/functions";

export default async function guest ({ next  }){
    const token = await getCookie('auth_token_default')
    if(token){
        return next({
            name: 'auth.home'
        })
    }
    return next()
}
