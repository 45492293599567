export default {
    delete: "Eliminar",
    close: "Cerrar",
    preview_document: "Vista Previa del Documento",
    tip_menu: {
        send_button: "Enviar",
        type_send: "Enviar",
        type_request: "Solicitar",
        your_message: "Tu Mensaje",
        your_want_to_buy: "Tu Mensaje",
    },
    static_links: {
        contact_support: "Contactar con el servicio de asistencia",
    },
    cc_bill: {
        complaints: "Solicitud de eliminación",
    },
}
