<template>
    <ul v-if="social_links" class="social-links">
        <li v-if="social_links.instagram">
            <a target="_blank" :href="social_links.instagram">
                <v-icon>mdi-instagram</v-icon>
            </a>
        </li>
        <li v-if="social_links.twitter">
            <a target="_blank" :href="social_links.twitter">
                <img :src="require('@/assets/images/logo/x.png')"
                     alt="x"
                     title="x"
                     width="24px"
                />
            </a>
        </li>
        <li v-if="social_links.discord">
            <a target="_blank" :href="social_links.discord">
                <img :src="require('@/assets/images/logo/discord.png')"
                     alt="discord"
                     title="discord"
                     width="24px"
                />
            </a>
        </li>
        <li v-if="social_links.telegram">
            <a target="_blank" :href="social_links.telegram">
                <img :src="require('@/assets/images/logo/telegram.png')"
                     alt="telegram"
                     title="telegram"
                     width="24px"
                />
            </a>
        </li>
    </ul>
</template>

<script>

import {SOCIAL_LINKS} from "@/configs/constants"

SOCIAL_LINKS
export default {
    name: "SocialLinks",
    data: () => ({
        social_links: SOCIAL_LINKS,
    }),
}
</script>

<style lang="scss">
@import '@/sass/modules/_variables';

.social-links {
    list-style-type: none;
    padding: 0 !important;
    display: flex;

    li {
        margin-right: 8px;
        margin-left: 8px;

        a {
            text-decoration: none;
            color: $text-base;

            &:hover .v-icon{
                color: #FC881B;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }
}
</style>
