const DEFAULT_TITLE = "Noodzly";
export { DEFAULT_TITLE };

const ENDPOINT = "http://localhost:80/api/";
export { ENDPOINT };

const ADMIN_QUANTITY_ITEMS_LIST = 15;
export { ADMIN_QUANTITY_ITEMS_LIST };

const ERROR_KEYS = {
    NOT_FOUND: "not_found",
};
export { ERROR_KEYS };

const YOTI_SCRIPT_URL = "https://www.yoti.com/share/client/";
export { YOTI_SCRIPT_URL };

const REFERRAL_BONUS = "25";
export { REFERRAL_BONUS };

const CURRENCY_SYMBOL = "$";
export { CURRENCY_SYMBOL };

const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
export { DEFAULT_DATE_FORMAT };

const INSUFFICIENT_FUNDS_ERROR = "insufficient_funds";
export { INSUFFICIENT_FUNDS_ERROR };

const LATENCY_TIME_STEP = 10;
export { LATENCY_TIME_STEP };

const LATENCY_SHOWING_STORY = 10;
export { LATENCY_SHOWING_STORY };

const SIGNUP_REFERRAL_CODE_LINK = "https://noodzly.com/sign-up";
export { SIGNUP_REFERRAL_CODE_LINK };

const MESSAGE_ON_WELCOME_PAGE =
    "Noodzly allows you to share your amateur content in total anonymity while getting paid and all content will be deleted after 24 hours.";
export { MESSAGE_ON_WELCOME_PAGE };

const STORY_ITEM_LENGTH_TIME = 60;
export { STORY_ITEM_LENGTH_TIME };

const AUDIO_MESSAGE_LENGTH_TIME = 60;
export { AUDIO_MESSAGE_LENGTH_TIME };

const SOCIAL_LINKS = {
    telegram: "https://t.me/+ZBlPRuiQHCNjOWVk",
    instagram: "https://www.instagram.com/noodzly/",
    twitter: "https://twitter.com/noodzly",
    discord: "https://discord.gg/Q2V7GFE2vg",
};
export { SOCIAL_LINKS };

const MAX_STORY_DESCRIPTION_LENGTH = 100;
export { MAX_STORY_DESCRIPTION_LENGTH };

const DEFAULT_STICKER_WIDTH = 100;
const STICKER_PATHS = [
    require("@/assets/images/stickers/png/close.png"),
    require("@/assets/images/stickers/png/minus.png"),
    require("@/assets/images/stickers/png/censored.png"),
    require("@/assets/images/stickers/png/blur.png"),
    require("@/assets/images/stickers/png/mosaic.png"),
    require("@/assets/images/stickers/png/advisory.png"),
    require("@/assets/images/stickers/svg/badass.svg").default,
    require("@/assets/images/stickers/svg/banana.svg").default,
    require("@/assets/images/stickers/svg/bandage.svg").default,
    require("@/assets/images/stickers/svg/bang.svg").default,
    require("@/assets/images/stickers/svg/boom.svg").default,
    require("@/assets/images/stickers/svg/broken-heart.svg").default,
    require("@/assets/images/stickers/svg/call-me.svg").default,
    require("@/assets/images/stickers/svg/drops.svg").default,
    require("@/assets/images/stickers/svg/eggplant.svg").default,
    require("@/assets/images/stickers/svg/fixing.svg").default,
    require("@/assets/images/stickers/svg/flame.svg").default,
    require("@/assets/images/stickers/svg/get-ready.svg").default,
    require("@/assets/images/stickers/svg/key.svg").default,
    require("@/assets/images/stickers/svg/lips.svg").default,
    require("@/assets/images/stickers/svg/love-arrow.svg").default,
    require("@/assets/images/stickers/svg/love.svg").default,
    require("@/assets/images/stickers/svg/no-minors.svg").default,
    require("@/assets/images/stickers/svg/oops.svg").default,
    require("@/assets/images/stickers/svg/padlock.svg").default,
    require("@/assets/images/stickers/svg/peach.svg").default,
    require("@/assets/images/stickers/svg/tired.svg").default,
    require("@/assets/images/stickers/svg/unicorn.svg").default,
    require("@/assets/images/stickers/svg/wow.svg").default,
    require("@/assets/images/stickers/svg/xoxo.svg").default,
    require("@/assets/images/stickers/svg/yeah.svg").default,
];

export { DEFAULT_STICKER_WIDTH, STICKER_PATHS };

const VIDEO_MP4_MIMETYPE = "video/mp4";
const VIDEO_WEBM_MIMETYPE = "video/webm";

const VIDEO_MP4_EXTENSION = "mp4";
const VIDEO_WEBM_EXTENSION = "webm";
const PHOTO_EXTENSION = "png";

export {
    VIDEO_WEBM_MIMETYPE,
    VIDEO_MP4_MIMETYPE,
    VIDEO_MP4_EXTENSION,
    VIDEO_WEBM_EXTENSION,
    PHOTO_EXTENSION,
};

const HASHTAG_SYMBOLS = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "-",
    "@",
];
const HASHTAG_BEFORE = "@";
export { HASHTAG_SYMBOLS, HASHTAG_BEFORE };

const SERVER_PROTOCOL = "https";
const DOMAIN_NAME = "noodzly.com";
const DEFAULT_STORY_DESTINATION = "my_story";
export { SERVER_PROTOCOL, DOMAIN_NAME, DEFAULT_STORY_DESTINATION };

const DEFAULT_FEES = 30;
export { DEFAULT_FEES };

const MIN_INTERNATIONAL_WIRE_TRANSFER_AMOUNT = 500;
export { MIN_INTERNATIONAL_WIRE_TRANSFER_AMOUNT };

const MIN_CRYPTO_WALLET_AMOUNT = 50;
export { MIN_CRYPTO_WALLET_AMOUNT };

const TRANSACTION_FEE = [ 
    { name: 'bank', fee: 1, type: 'absolute' },
    { name: 'bitsafe', fee: 1, type: 'absolute' },
    { name: 'international_wire_transfer', fee: 45, type: 'absolute' },
    { name: 'crypto_wallet', fee: 2.5, type: 'percent' },
]
export { TRANSACTION_FEE };

export const FRESHDESK_TYPES = [
    { value: 'General', text: 'General' },
    { value: 'Models', text: 'Models' },
    { value: 'Payments', text: 'Payments' },
    { value: 'Refund', text: 'Refunds' },
    { value: 'Bugs', text: 'Bugs' },
    { value: 'Press', text: 'Press' },
    { value: 'Feature Request', text: 'Suggest a feature' },
];