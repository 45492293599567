<template>
     <div class="fill-height">
        <v-container>
            <auth-header title="OTP Verification"/>

            <v-row justify="center" align="center">
                <div class="EmailVerify-icon">
                    <email-opened/>
                </div>
            </v-row>

            <v-row>
                <div class="mt-4 ml-3">
                    {{ $t('adminPanel.login_verification_text', { user: user.name, email: user.email }) }}
                </div>
            </v-row>
            <form class="auth-form mt-6">
                <v-text-field
                    v-model="code"
                    label="Verification Code"
                    class="mb-5"
                />

                <btn-large class="btn-accent mt-8" @click="verifyCode" text="Verify Code"/>
            </form>

            <!-- Resend OTP Section -->
            <div class="mt-5">
                <v-btn class="btn-accent" @click="resendOtp" :disabled="isTimerActive" > Resend OTP </v-btn>
                <span v-if="isTimerActive">
                    Resend in {{ timer }}s
                </span>
            </div>
        </v-container>
    </div>
</template>

<script>
import { mapState, mapMutations} from 'vuex'
import AuthHeader from '@/components/app/auth/AuthHeader';
import EmailOpened from "@/assets/images/icons/email-opened.svg?inline"

export default {
    name: "otp-verification",
    data() {
        return {
            code: null,
            timer: 30,
            isTimerActive: false,
            timerInterval: null,
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.user.data,
        })
    },
    watch: {

    },
    methods: {
        ...mapMutations(["showSnackBar"]),

        verifyCode(){
            axios
                .post("/api/admin/verify-code", {
                    email: this.user.email,
                    code: this.code
                },  { hideLoader: false })
                .then((response) => {
                    localStorage.setItem('otp_verified', true)
                    this.$router.push({'name': 'admin.index'}).catch((e) => console.log(e))
                })
                .catch((error) => {
                    console.log(error.response.data)
                    this.showSnackBar(error.response.data.message);
                });
        },

        resendOtp() {
            axios.post('/api/admin/resend-verification-code', {
                email: this.user.email,
            })
            .then((response) => {
                this.showSnackBar(this.$t("adminPanel.code_resent"));
                this.startTimer();
            })
            .catch((error) => {
                console.error('Error Resending OTP:', error.response);
            });
        },

        startTimer() {
            this.isTimerActive = true;
            this.timer = 30;

            this.timerInterval = setInterval(() => {
                this.timer--;
                if (this.timer <= 0) {
                    this.stopTimer();
                }
            }, 1000);
        },

        stopTimer() {
            clearInterval(this.timerInterval);
            this.isTimerActive = false;
            this.timer = 0;
        },

    },
    mounted() {
        this.startTimer();
    },
    beforeDestroy() {
        if (this.timerInterval) {
          clearInterval(this.timerInterval);
        }
    },
    components: {
        AuthHeader,
        EmailOpened,
    }
}
</script>

<style lang="scss" scoped>

</style>
