<template>
    <app-transition>
        <Dialog
            :only-text="true"
            @close="handleClose"
            class="contact-us-dialog"
        >
        <v-row>
            <h2>{{ $t('dialogs.contact_us.form_title') }}</h2>
        </v-row>

        <v-form ref="form" class="mt-5 w-full px-5">
            <v-row>
                <v-text-field
                    solo
                    background-color="rgba(237,237,237, .1)"
                    :label="$t('dialogs.contact_us.email')"
                    v-model="email"
                    :rules="rules"
                />
            </v-row>
            <v-row>
                <v-select
                    solo
                    background-color="rgba(237,237,237, .1)"
                    :items="freshdeskTypes"
                    item-value="value"
                    item-text="text"
                    :label="$t('dialogs.contact_us.type_label')"
                    v-model="selectedFreshdeskType"
                    :rules="rules"
                >
                    <template #append>
                        <v-icon size="36">mdi-menu-down</v-icon>
                    </template>
                </v-select>
            </v-row>
            <v-row>
                <v-textarea
                    color="rgba(237,237,237, .1)"
                    background-color="rgba(237,237,237, .1)"
                    no-resize
                    solo
                    v-model="question"
                    :rules="rules"
                    :placeholder="$t('dialogs.contact_us.question.placeholder')">
                </v-textarea>
            </v-row>
            <v-row>
                <turnstile-captcha explicit-render @verified="captchaToken = $event" ref="captcha" />
            </v-row>
            <span v-if="errors.captcha_token" class="red--text"><strong>* </strong>
                {{ errors.captcha_token[0] }}
            </span>
            <v-row justify="center" class="pt-7">
                <gradient-button block @click="save" :loading="isLoading" >
                    <span v-if="!isLoading && !requestSubmitted">
                        {{ $t('dialogs.contact_us.send') }}
                    </span>
                    <span v-else>
                        {{ $t('dialogs.contact_us.sent') }}
                    </span>
                </gradient-button>
            </v-row>
        </v-form>
        </Dialog>
    </app-transition>
</template>

<script>
import breaks from "@/mixins/breaks";
import Dialog from "@/components/app/form/Dialog";
import { FRESHDESK_TYPES } from "@/configs/constants";
import GradientButton from "@/components/app/button/GradientButton";
import { mapActions } from "vuex";
import TurnstileCaptcha from '@/components/app/auth/TurnstileCaptcha';

export default {
    name: "ContactUsDialog",
    mixins: [breaks],
    data: () => ({
        question: null,
        email: null,
        freshdeskTypes: FRESHDESK_TYPES,
        selectedFreshdeskType: null,
        rules: [
            (v) => !!v || 'This field is required',
        ],
        isLoading: false,
        requestSubmitted: false,
        captchaToken: null,
        errors: {},
    }),
    methods: {
        handleClose() {
            this.$emit('close');
        },
        save(){
            if (!this.$refs.form.validate()) return;
            this.isLoading = true;

            this.sendContactAsGuest({
                email: this.email, question: this.question, type: this.selectedFreshdeskType, captchaToken: this.captchaToken, isMobile: this.mdAndDown
            }).then((response) => {
                this.success = response.success
                this.question = ''

                this.$refs.form.resetValidation();
                this.isLoading = false;
                this.requestSubmitted = true;

                setTimeout(() => {
                    this.handleClose()
                }, 2000);
            }, (e) => {
                this.errors = e.response.data.errors;
                this.isLoading = false;
                this.$refs.captcha.reset();
            })
        },

        ...mapActions(['sendContactAsGuest'])
    },
    components: {
        Dialog,
        GradientButton,
        TurnstileCaptcha,
    },
}
</script>

<style lang="scss">
@import "@/sass/modules/_variables";

.contact-us-dialog {
    .DialogContent {
        width: 100% !important;
        max-width: 400px !important;
        background: black;
        border: 1px solid #2e2e2e;
        box-shadow: 0 0 40px 5px rgba(255, 255, 255, 0.15);
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 24px !important;

        button:first-child {
            background: $accent-background !important;
        }

        @media only screen and (max-width: 960px) {
            max-width: 350px !important;
        }
    }
}
</style>
