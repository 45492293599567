<template>
    <setting-layout :title="$t('pages.user.setting.contact.title')">
        <v-container>
            <v-form ref="form">
                <v-row>
                    <v-select
                        solo
                        background-color="rgba(237,237,237, .1)"
                        :items="freshdeskTypes"
                        item-value="value"
                        item-text="text"
                        :label="$t('pages.user.setting.contact.type_label')"
                        v-model="selectedFreshdeskType"
                        :rules="rules"
                    >
                        <template #append>
                            <v-icon size="36">mdi-menu-down</v-icon>
                        </template>
                    </v-select>
                </v-row>
                <v-row>
                    <v-textarea
                        color="rgba(237,237,237, .1)"
                        background-color="rgba(237,237,237, .1)"
                        no-resize
                        solo
                        v-model="question"
                        :error-messages="errors.message"
                        :success-messages="success.message"
                        :rules="rules"
                        :placeholder="$t('pages.user.setting.contact.placeholder')">
                    </v-textarea>
                </v-row>
                <v-row justify="center" class="pt-7 pl-7 pr-7">
                    <gradient-button block @click="save" :loading="$store.getters.loadingBtn(['setting', 'contactUs'])" >
                        {{ $t('pages.user.setting.contact.send') }}</gradient-button>
                </v-row>
            </v-form>
        </v-container>
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import GradientButton from "@/components/app/button/GradientButton";
import {mapActions, mapState} from "vuex";
import { FRESHDESK_TYPES } from "@/configs/constants"

export default {
    name: "ContactUs",
    data: () => ({
        question: '',
        errors: {},
        success: {},
        freshdeskTypes: FRESHDESK_TYPES,
        selectedFreshdeskType: null,
        rules: [
            (v) => !!v || 'This field is required',
        ],
    }),
    computed: {
        ...mapState({
            user: (state) => state.user.user.data,
        }),
    },
    methods: {
        save(){
            if (!this.$refs.form.validate()) return;
            this.$mixpanel.click('Contact Us', 'Contact Us Screen');

            this.sendContact({question: this.question, type: this.selectedFreshdeskType}).then((response) => {
                this.success = response.success
                this.question = ''

                this.$refs.form.resetValidation();
            }, (e) => this.errors = e.response.data.errors)
        },
        ...mapActions(['sendContact'])
    },

    mounted() {
        this.$mixpanel.identifyUser(this.user.email);
        this.$mixpanel.pageViewed('Contact us');
    },

    components: {GradientButton, SettingLayout}
}
</script>

<style scoped>

</style>
