<template>
    <setting-layout :title="$t('pages.user.setting.password.title')">
        <v-container>
            <v-form ref="form">
                <v-text-field
                    solo
                    background-color="rgba(237,237,237, .1)"
                    type="text"
                    :placeholder="this.$t(`pages.user.setting.password.old_password`)"
                    v-model="form.old_password"
                    :rules="rules"
                    :error-messages="errors.old_password"
                />
                <v-text-field
                    solo
                    background-color="rgba(237,237,237, .1)"
                    v-model="form.new_password"
                    :type="changeInputType"
                    :placeholder="this.$t(`pages.user.setting.password.new_password`)"
                    :rules="passwordRules"
                    :error-messages="errors.new_password"
                >
                    <template #append>
                        <v-icon @click="showPassword = !showPassword">{{ changePasswordIcon }}</v-icon>
                    </template>
                </v-text-field>
                <v-text-field
                    solo
                    background-color="rgba(237,237,237, .1)"
                    type="password"
                    :rules="[passwordConfirmationRule]"
                    :placeholder="this.$t(`pages.user.setting.password.confirm_password`)"
                    v-model="form.new_password_confirmation"
                    :error-messages="errors.new_password_confirmation"
                />
                <v-row justify="center" class="px-3 pt-8">
                    <gradient-button block @click="save"
                                     :loading="$store.getters.loadingBtn(['setting', 'passwordChangeBtn'])">
                        {{ $t('pages.user.setting.password.update') }}
                    </gradient-button>
                </v-row>
            </v-form>
        </v-container>
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import TextField from "@/components/app/form/TextField";
import GradientButton from "@/components/app/button/GradientButton";
import {mapActions, mapState} from "vuex";

export default {
    name: "Password",
    data: () => ({
        form: {
            old_password: '',
            new_password: '',
            new_password_confirmation: '',
        },
        errors: {},
        rules: [(v) => !!v || "This field is Required"],
        passwordRules: [
            (v) => !!v || "This field is required",
            (v) =>
                (v &&
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/.test(
                    v
                )) ||
                "One capital letter, Special character, Number",
            (v) => v.length >= 8 || "Password should contain at least 8 characters.",
        ],
        showPassword: false,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user.user.data,
        }),
        view() {
            return JSON.stringify(this.inputs)
        },
        changePasswordIcon() {
            return this.showPassword ? "mdi-eye" : "mdi-eye-off";
        },
        changeInputType() {
            return this.showPassword ? "text" : "password";
        },
        passwordConfirmationRule() {
            return () => {
                if (!this.form.new_password && !this.form.new_password_confirmation) return true;

                if (this.form.new_password === this.form.new_password_confirmation) return true;

                return "Password must match";
            };
        },
    },
    methods: {
        save() {
            if (!this.$refs.form.validate()) return;
            this.$mixpanel.click('Update Password', 'Settings Screen');

            this.errors = {};
            this.sendPasswordChange({model: this.form})
                .then(() => {
                    this.form = {
                        old_password: '',
                        new_password: '',
                        new_password_confirmation: '',
                    }
                    this.$refs.form.resetValidation();
                    this.$store.commit('showSnackBar', this.$t('messages.password_changed'))
                })
                .catch(error => {
                    this.errors = error.response.data.errors
                })
        },
        ...mapActions(['sendPasswordChange'])
    },

    mounted() {
        this.$mixpanel.identifyUser(this.user.email);
        this.$mixpanel.pageViewed('Password update');
    },

    components: {GradientButton, TextField, SettingLayout}
}
</script>

<style scoped>

</style>
