import {requestCore} from "@/core/index";
import { getCookie } from "@/helpers/functions";

class RequestCore {
    constructor() {
        this.mockApi = true;
    }

    getToken() {
        return getCookie('auth_token_default')
    }

    buildFormData(formData, data, parentKey) {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            const value = data == null ? '' : data;

            formData.append(parentKey, value);

        }
    }

    async POST(method, data, url = '', localMocky = true, commit, headers = false, withoutLoading = false) {
        /*await fetch(this.mockApi === true?url:`/api/${method}`, {
           method: 'POST',
           headers: {
               //'Content-Type': 'application/json;charset=utf-8',
               'Origin': 'localhost:8080'
           },
           body: data
       });*/
        if (!headers) {
            headers = {
                'Content-type': 'application/json; charset=utf-8',
                'Accept': 'application/json; charset=utf-8'
            }
        }
        let res = await window.axios({
            method: 'post',
            url: this.mockApi && localMocky === true ? url : `/api/${method}`,
            headers: headers,
            data,
            hideLoader: withoutLoading
        })
        if ((res.status !== 200 || res.status !== 201) && res.data.err_msg !== undefined) {
            commit('showSnackBar', 'Error: ' + res.data.err_msg)
            console.log('catch err', res)
        }
        return res

        /*return await axios.post('http://localhost/api/stories', data)*/
    }

    async GET(method, url, params = {}, localMocky = true, commit, withoutLoading = false) {

        if (localMocky === true) {
            let res = await fetch((this.mockApi === true && localMocky ? url : `/api/${method}`) + "?" + new URLSearchParams(params), {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json; charset=utf-8',
                    'Accept': 'application/json; charset=utf-8',
                    //'Origin': 'localhost:8080'
                },
                params,
                hideLoader: withoutLoading
                //body: data
            });

            res = await res.json();
            return res;
        } else {

            let res = await window.axios({
                method: 'get',
                url: `/api/${method}` + "?" + new URLSearchParams(params),
                headers: {
                    'Content-type': 'application/json; charset=utf-8',
                    'Accept': 'application/json; charset=utf-8',
                    //'Origin': 'localhost:8080',
                    'Authorization': 'Bearer ' + this.getToken()
                },
                data: params,
                hideLoader: withoutLoading
            })
            if ((res.status !== 200 || res.status !== 201) && res.data.err_msg !== undefined) {
                commit('showSnackBar', 'Error: ' + res.data.err_msg)
                //console.log('catch err', res)
            }
            return res.data;
        }
    }

    buildLoadingName(name) {
        return typeof name === 'object' ? name.join('/') : name;
    }

    async getData(method, loadingName, mutationName, commit, url = '/api/echo', params = {}, localMocky = true, withoutLoading = false) {
        if (mutationName === '' && loadingName === '') {
            return await requestCore.GET(method, url, params, localMocky, commit);
        } else if (withoutLoading) {
            let res = await requestCore.GET(method, url, params, localMocky, commit, withoutLoading)
            commit(mutationName, res)
            return res
        } else {
            loadingName = this.buildLoadingName(loadingName)
            commit('setMainUnloadedData', loadingName);
            let res = await requestCore.GET(method, url, params, localMocky, commit)
            //res = await res.json()
            commit(mutationName, res)
            commit('setMainLoadedData', loadingName);
            return res;
        }
    }

    async setData(method, model, loadingName, mutationName, commit, url = '/api/echo', localMocky, withoutLoading = false) {
        if (!withoutLoading) {
            loadingName = this.buildLoadingName(loadingName)
            commit('setBtnLoadingStart', loadingName)
        }
        let data = new FormData();
        if (typeof model !== 'string' && typeof model !== 'number')
            requestCore.buildFormData(data, model);
        else
            data.append('value', model)
        //data.append('file', file)
        let res = {}
        try {
            res = await requestCore.POST(method, data, url, localMocky, commit, false, withoutLoading)
            //res = res.data
            if (mutationName !== '') {
                commit(mutationName, res.data)
            }
        } finally {
            if (!withoutLoading) {
                commit('setBtnLoadingEnd', loadingName)
            }
        }
        return res.data;
    }

    async setDataWithForm(method, formData, loadingName, mutationName, commit, url = '/api/echo', localMocky = true) {
        loadingName = this.buildLoadingName(loadingName)
        commit('setBtnLoadingStart', loadingName)
        //data.append('file', file)
        await requestCore.POST(method, formData, url, localMocky)
        //res = await res.json()
        commit('setBtnLoadingEnd', loadingName)

    }

    async deleteData(method, data, mutation, commit, url = '/api/echo', localMocky = true) {
        let res = await window.axios({
            method: 'delete',
            url: this.mockApi === true && localMocky === true ? url : `/api/${method}`,
            headers: {
                'Content-type': 'application/json; charset=utf-8',
                'Accept': 'application/json; charset=utf-8'
            },
            data
        })
        if (mutation !== '') {
            commit(mutation)
        }
        if (typeof res.json !== 'function') {
            return await res.data
        }
        return await res.json();

    }

    async patch(method, data, loadingName, commit) {
        loadingName = this.buildLoadingName(loadingName)
        commit('setBtnLoadingStart', loadingName)
        return await window.axios({
            method: 'patch',
            url: `/api/${method}`,
            headers: {
                'Content-type': 'application/json; charset=utf-8',
                'Accept': 'application/json; charset=utf-8'
            },
            data
        })
            .then(res => res.data)
            .finally(() => commit('setBtnLoadingEnd', loadingName))

        //return await res.json();

    }

    isAuth() {
        return !(localStorage.getItem('auth_default_token') === null)
    }

    setAuth($auth) {
        this.$auth = $auth;
        console.log($auth);
    }

    setUserData($auth, name, value) {
        let obj = {...$auth.user()};
        obj[name] = value;
        return $auth.user(obj)
    }

    /**
     * @alias rQ.setUserData
     * @param $auth
     * @param name
     * @param value
     * @returns {*}
     */
    sud($auth, name, value) {
        return this.setUserData($auth, name, value)
    }

}

export default new RequestCore()
