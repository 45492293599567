import languages from "./en/languages";
import pages from "./en/pages";
import messages from "./en/messages";
import dialogs from "./en/dialogs";
import components from "./en/components";
import adminPanel from "./en/admin-panel";

export  default {
    languages,
    pages,
    messages,
    dialogs,
    components,
    adminPanel,
}
