import {requestCore} from '@/core/index'
import {isSafari} from "../helpers/functions";

const state = {
    imageDataUrl: '',
    imageDataWidth: 0,
    imageDataHeight: 0,
    imageBlob: new Blob(),

    videoDataUrl: '',
    videoDuration: 0,
    videoBlob: new Blob(),

    overlay: new Blob(),
    fileResolution: {
        width: false,
        height: false,
    },
    overlayDataUrl: '',
    isMuted: isSafari(),

    isShowCameraScreen: {
        viewCamera: false,
        viewResult: false,
        viewBeforePosting: false,
        step: 4,
        fromChat: {},
    },

    filters: {
        sections: [
            'subscribed',
            'following',
            'discover',
        ],
        range: [18, 100],
        panel: '',
        tags: [],
        activeGenders: [],
        categoriesPanel: '',
        categories: [],
    },

    tags: [],
    type: 'photo',
    statistics: {
        tips: [],
        tipsTotal: 0,
        purchased: [],
        purchasedTotal: 0,
        total: 0
    },
    userStories: [],
    storyLoaded: false,
    profileStory: {
        purchased: [],
        tips: [],
        tips_total: 0,
        purchased_total: 0,
        total: 0,
    },
    currentStory: {},
    activeStory: 0,
    storyView: {
        isRepeat: false,
        isSoundOn: true,
        isStopStory: false,
        isStopVideo: false,
        isOverlayShow: true,
        isLoadedStory: false,
    }
};

const getters = {};


const mutations = {
    setId(state, {id}) {
        state.currentStory.id = id;
    },
    setStep: (state, step) => {
        state.isShowCameraScreen = {...state.isShowCameraScreen, step: step}
    },
    setStepFromChat: (state, {step, fromChat}) => {
        state.isShowCameraScreen = {...state.isShowCameraScreen, step: step, fromChat: fromChat}
    },
    setImageDataUrl(state, {image, width, height}) {
        state.imageDataUrl = image;
        state.imageDataWidth = width;
        state.imageDataHeight = height;
    },
    setImage(state, {image}) {
        state.imageBlob = image;
    },

    setVideo(state, {blob}) {
        state.videoBlob = blob
    },

    setVideoDataUrl(state, url) {
        state.videoDataUrl = url
    },

    setVideoDuration(state, duration) {
        state.videoDuration = duration
    },

    setFileResolution(state, {width, height}) {
        state.fileResolution = {
            width: width,
            height: height,
        }
    },

    setType(state, type) {
        state.type = type;
    },

    setActiveStory(state, index) {
        state.activeStory = index;
    },

    setOverlay(state, overlay) {
        state.overlay = overlay
    },
    setOverlayDataUrl(state, overlay) {
        state.overlayDataUrl = overlay
    },
    setStory(state, story) {
        state.currentStory = story;
    },
    setStoryLoaded(state) {
        state.storyLoaded = true;
    },
    setStoryUnloaded(state) {
        state.storyLoaded = false
    },
    setTags(state, tags) {
        state.tags = tags
    },
    setFilters(state, payload) {
        state.filters = payload
    },
    setUserStories(state, stories) {
        state.userStories = stories;
    },
    toggleMute(state) {
        state.isMuted = !state.isMuted
    },
    toggleStoryViewSound(state) {
        state.storyView.isSoundOn = !state.storyView.isSoundOn
    },
    setProfileStory(state, payload) {
        state.profileStory = payload
    },
    setUnlockStory() {},
    setStopStory(state, payload) {
        state.storyView.isStopStory = payload
    },
    setIsLoadedStory(state, payload) {
        state.storyView.isLoadedStory = payload
    },
    setStoryViewIsRepeat(state, payload) {
        state.storyView.isRepeat = payload
    },
    toggleStopStory(state) {
        state.storyView.isStopStory = !state.storyView.isStopStory
    },
    stopStory(state) {
        state.storyView.isStopStory = true
    },
    startStory(state) {
        state.storyView.isStopStory = false
    },
    toggleStopVideo(state) {
        state.storyView.isStopVideo = !state.storyView.isStopVideo
    },
    stopVideo(state) {
        state.storyView.isStopVideo = true
    },
    startVideo(state) {
        state.storyView.isStopVideo = false
    },
};

const actions = {
    async sendStory({commit}, {model, file, muted, width, height, overlayDataUrl, videoDuration, isFrontCamera, isLandscape, type, slowMotionFrame, normalFrame}) {
        let formData = new FormData();
        formData.append('type', type)
        formData.append('overlay', overlayDataUrl)
        formData.append('videoDuration', videoDuration)
        formData.append('muted', muted)
        formData.append('width', width)
        formData.append('height', height)
        formData.append('isFrontCamera', isFrontCamera)
        formData.append('isLandscape', isLandscape)
        if (slowMotionFrame) {
            formData.append('slowMotionFrame', JSON.stringify(slowMotionFrame))
        }
        if (normalFrame) {
            formData.append('normalFrame', JSON.stringify(normalFrame))
        }


        if (type === 'photo') {
            formData.append('story', file, 'story.png')
        } else if (type === 'video') {
            formData.append('story', file, isSafari() ? 'story.mp4' : 'story.webm')
        } else {
            throw new Error('Error within types of files')
        }

        requestCore.buildFormData(formData, model);
        let response = await requestCore.POST('stories', formData, '/api/stories', false, commit, {
            'Content-type': 'multipart/form-data'
        })

        return response;
    },
    async getStory({commit}, {id}) {
        return await requestCore.getData(
            `stories/${id}`,
            'getStory',
            'setStory',
            commit,
            'https://run.mocky.io/v3/64425a35-9a43-40de-b3ea-7112407b7706',
            {},
            false
        );
    },
    async getTags({commit}) {
        return await requestCore.getData(
            `tags`,
            ['story', 'tags'],
            'setTags',
            commit,
            `https://run.mocky.io/v3/17d929a9-362c-4276-b0a6-e25a9467c2f0`,
            {},
            false,
            true
        )
    },
    async getUserStories({commit}) {
        return await requestCore.getData(
            `stories/user`,
            ['story', 'userStories'],
            'setUserStories',
            commit,
            `https://run.mocky.io/v3/17d929a9-362c-4276-b0a6-e25a9467c2f0`,
            {},
            false
        )
    },
    async sendEditedStory({commit}, {model}) {
        return await requestCore.POST('stories/edit', model, '/api/stories/edit', false, commit)
    },
    async deleteStory({commit}, {model}) {
        return await requestCore.deleteData('stories/delete', {id: model.id}, '', commit, '/api/stories/delete')
    },
    async sendUserWatchStory({commit}, id) {
        return await requestCore.patch(`stories/${id}`, '', '', commit)
    },
    async sendReportStory({commit}, {story_id, accusation}) {
        return await requestCore.POST('stories/report', {story_id: story_id, accusation: accusation}, '/api/stories/report', false, commit)
    },
    async getStatistics({commit}, id) {
        return await requestCore.getData(
            `stories/statistics/${id}`,
            ['story', 'statistics'],
            'setProfileStory',
            commit,
            '',
            {},
            false
        )
    },
    async unlockStory({commit}, id) {
        return await requestCore.getData(`stories/unlock/${id}`, [], 'setUnlockStory', commit, '', {}, false)
    },
};

export default {
    state, getters, mutations, actions
}
