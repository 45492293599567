export default {
    are_you_sure: "Вы уверены?",
    yes: "Да",
    no: "Нет",
    back: "Назад",
    copied_clipboard: "Скопировано",
    filters: "Фильтры",
    section: "Раздел",
    reset: "Сброс",
    subscribed: "Подписан",
    following: "Подписан",
    discover: "Обнаружить",
    age: "Возраст",
    sex: "Пол",
    male: "Мужской",
    female: "Женский",
    transgender: "Трансгендер",
    save: "Сохранить",
    add: "Добавить",
    tags: "Теги",
    done: "Готово",
    minimum_payment: "Минимальный платеж",
    receive_payment: "Дни до получения платежа",
    check_out_more: "Узнать больше",
    days: "Дней",
    hours: "Часов",
    minutes: "Минут",
    use_default: "Использовать по умолчанию",

    unlock_verify_account: "Для разблокировки подтвердите свою учетную запись",
    messages_disabled: "Упс.. Сообщения отключены",
    not_contents: "У вас еще нет контента",
    direct_message_title: "Прямое сообщение",
    direct_message_description: "Написать сообщение",
    subscribe: "ПОДПИСАТЬСЯ",
    month: "Месяц",
    direct_message: "ПРЯМОЕ СООБЩЕНИЕ",
    balance_insufficient:
        "Упс.. ваш баланс недостаточен. Вы можете пополнить его, просто нажав на кнопку ниже",
    positive_balance_error_on_account_deletion:
        "Вы должны вывести средства из кошелька перед удалением профиля",
    has_subscribers_error_on_account_deletion:
        "Ваша учетная запись будет удалена, когда истечет срок подписки вашего последнего подписчика на {renewal_at}. Убедитесь, что вывели все ваши средства, иначе ваш аккаунт не будет удален",
    wallet: "кошелек",
    report: "Пожаловаться",
    report_placeholder: "Дайте нам знать, почему вы хотите подать жалобу",
    send: "Отправить",
    subscriptions: "Подписки",
    subscription_include: "Подписка включает:",
    active_until: "Активен до ",
    support_model: "Поддержите Noodzler",
    unlock_all_stories: "Разблокировать весь контент",
    unlimited_direct_messages: "Неограниченные прямые сообщения",
    unlock_content: "Разблокировать контент за",
    confirm_ending_stream: "Вы уверены, что хотите завершить трансляцию?",
    no_streams_scheduled: `{username} не запланировал ни одной трансляции`,
    not_available_to_start_streaming:
        "Трансляция начнется в {date}, в {difference}",
    streams_restricted: "Вы были заблокированы",
    cancel_subscription: "Отмена подписки",
    cancel_subscription_desc:
        "Ваша подписка закончится на следующей дате продления, и вас не будут взиматься платежи.",
    your_must: "Вам нужно",
    your_must_before_content:"Чтобы сделать Нудзли более безопасным местом, вы должны",
    verify_account: "подтвердить свою учетную запись",
    unlock: "Разблокировать",
    update: "Обновить",
    open: "Открыть",
    reset_password: "Сброс пароля",
    password: "Пароль",
    new_password: "Новый пароль",
    verify_password: "Подтвердите пароль",
    forget_password: "Забыли пароль",
    password_reset_confirmed: "Подтвержден сброс пароля",
    error_token_email: "Требуется токен и электронная почта.",
    email: "Электронная почта",
    write_something: "Что нового?",
    my_story: "МОЯ ИСТОРИЯ",
    subscribers: "ПОДПИСЧИКИ",
    chat: "ЧАТ",
    free: "Бесплатно",
    post: "ПОСТ",
    tags_hint: "Добавьте не более 3 тегов",
    boost_tags_hint: "Добавьте не более 10 тегов",
    redo: "Повторить",
    set_image: "Установить изображение",
    purchased: "КУПЛЕНО",
    gives_you: "дает вам",
    tips: "ЧАЕВЫЕ",
    total: "всего",
    delete_story_hint:
        "Вы не потеряете своих заработков, но если кто-то уже купил его, он будет удален через 24 часа после покупки.",
    modify_content: "Изменить ваш контент",
    your_message: "Ваше сообщение",
    profile_link: "Ссылка на профиль",
    unblock: "Разблокировать",
    block: "Блокировать",
    subscribe_for: "Подпишитесь на",
    top_up: "Пополнить",
    top_up_via_crypto: "Пополнить через Крипто",
    top_up_via_card: "Пополнить через Карту",
    top_up_via_paypal: "Пополнение через PayPal",
    top_up_warning: "* Может взиматься НДС и комиссия за транзакцию",
    active: "Активно",
    expired: "Истекло",
    cancel: "Отмена",
    renew: "Продлить",
    balance: "Баланс",
    withdraw: "Вывести",
    my_subscriptions: "Мои подписки",
    from: "От",
    to: "К",
    email_verify: "Подтвердите электронную почту",

    or: "или",
    delete: "удалить",
    unfollow: "отписаться",
    something_is_missing: "Упс.. Что-то отсутствует",
    to_turn_off_price: "чтобы отключить сообщения, установите цену в 0 в",
    noodzlers_cant_turn_off_messages: "Noodzlers не могут отключить сообщения",
    set_messages_price_error:
        "Цена за сообщения не должна быть больше цены подписки - ",
    set_subscription_price_error:
        "Цена подписки должна быть больше цены сообщения - ",
    messages_page: "странице сообщений",
    subscription_page: "странице подписок",

    request: "Запрос",
    send_funds: "{username} не может получать чаевые.",
    countdown_text: "10-секундный обратный отсчет начнется для",
    photo: "Фото",
    video: "Видео",

    access_camera_title: "Камера и микрофон",
    access_camera_description:
        "Noodzly хочет использовать вашу камеру и микрофон.",
    access_denied_camera_description:
        "Вы заблокировали доступ к камере и микрофону, приложение не будет работать. Проверьте настройки вашего браузера.",
    deny: "Отказать",
    allow: "Разрешить",
    accept: "Принять",
    confirm_your_password:
        "Для удаления вашей учетной записи подтвердите ваш пароль",
    password_placeholder: "Введите ваш пароль",
    confirm_and_delete: "Подтвердить и удалить",
    subscribe_for_free: "ПОДПИСАТЬСЯ БЕСПЛАТНО",
    has_no_tips: "Пока нет предложений",
    block_desktop: {
        only_mobile: "Пожалуйста, используйте мобильную версию",
        title: "Для лучшего опыта рекомендуем использовать мобильную версию. Используйте руководство ниже:",
        iphone: {
            title: "iPhone",
            list: [
                "Откройте Safari на вашем iPhone и перейдите на Noodzly.com",
                'Нажмите на значок "Поделиться" внизу. Он выглядит как квадрат с стрелкой вверх.',
                'Выберите значок "Добавить на домашний экран".',
                "Наслаждайтесь!",
            ],
        },
        android: {
            title: "Android",
            list: [
                "Откройте Google Chrome и перейдите на Noodzly.com",
                'Нажмите на значок "Параметры". Он может выглядеть как три вертикальные точки вверху.',
                'Нажмите "Добавить на домашний экран".',
                "Наслаждайтесь!",
            ],
        },
    },

    withdrawal_warning: {
        bank_transfer: {
            description: "Минимальная сумма для вывода - 100 долларов",
        },
        bitsafe: {
            description: "Минимальная сумма для вывода - 50 долларов",
        },
        wire_transfer: {
            description: "Минимальная сумма для вывода составляет {min_amount}{currency}"
        },
        crypto_wallet: {
            description: "Минимальная сумма для вывода составляет {min_amount}{currency}"
        },
    },

    withdrawal_form_error: {
        bank_transfer: {
            title: "Предупреждение",
            description:
                "Добавьте недостающую информацию на странице банковского перевода",
        },
        bitsafe: {
            title: "Предупреждение",
            description: "Добавьте недостающую информацию на странице bitsafe",
        },
        wire_transfer: {
            title: "Предупреждение",
            description: "Добавьте недостающую информацию на страницу международного банковского перевода.",
        },
        crypto_wallet: {
            title: "Предупреждение",
            description: "Добавьте недостающую информацию на свою страницу Crypto",
        },
    },
    no_content_warning: "Загрузите хотя бы один контент в свою историю.",
    content_expiring: "Срок действия ваших историй меньше времени акции.",
    filter_select: "Пожалуйста, выберите хотя бы один раздел",
    subscription_cancelled: "Подписка успешно отменена",
    renewal_message: "{user_name} измененный {gender} подписка от {old_price} к {new_price}, хотели бы продлить?",
    subscription: "Подписка",
    max_price_error: "Цена должна быть между {min_dm_price} и {max_dm_price}",
    transaction: 'Сделка',
    invalid_tip: 'Совет должен быть больше 0',
    referral_code_saved: 'Реферальный код успешно сохранен',
    boost: {
        saved: 'Усиление создано успешно',
    },
    post_upload: {
        success: 'Ура! Успех!',
        failed: 'Упс! Попробуйте еще раз',
    },
    ok: 'Хорошо',
    tap_back:'Нажмите «Назад»',
    tap_next:'Нажмите «Далее»',
    business_days: 'Рабочие дни',
    pause:'Пауза',
    fee: 'Платеж',
    payment_method: {
        not_saved: "Данные не сохранены, что-то пошло не так",
        saved: "Сохранено",
    },
    video_guide: {
        swipe_gestures: "Нажмите и перетащите для навигации по видео",
    },
    contact_us: {
        form_title: "Связаться со службой поддержки",
        email: "Электронная почта",
        type_label: "Что привело вас сюда?",
        question: {
            placeholder: "Как дела?"
        },
        send: "Отправлять",
        sent: "Отправил",
    },
};
