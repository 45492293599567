import languages from "./it/languages";
import pages from "./it/pages";
import messages from "./it/messages";
import dialogs from "./it/dialogs";
import components from "./it/components";
import adminPanel from "./it/admin-panel";

export  default {
    languages,
    pages,
    messages,
    dialogs,
    components,
    adminPanel,
}
