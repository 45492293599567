export default function otp_verified({next}) {
    const otp_verified = localStorage.getItem('otp_verified')

    if (otp_verified === 'false') {
        return next({
            name: 'admin.verify-otp'
        })
    }
    return next()
}
